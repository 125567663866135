import { Component } from '@angular/core';


@Component({
    selector: 'docs-app',
    templateUrl: './docs.template.html',
    styleUrls: ['./docs.scss']
})
export class DocsComponent {

}
