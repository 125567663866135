import { Component } from '@angular/core';


@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.template.html',
    styleUrls: ['./homepage.scss']
})
export class HomepageComponent {

}

